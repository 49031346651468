import { Movimentacao } from "../../components/Mobile/Movimentacao";
import { UseExtratoProvider } from "../../Hook/user/useExtrato";

export function PageMovimentacao() {
    return (
        <UseExtratoProvider>
            <Movimentacao />
        </UseExtratoProvider>
    );
}
