import { ModalConfig } from "../config";
import ModalBottom from "../../ModalBottom";
import { AlertaBoleto } from "../../Mobile/AlertaBoleto";
import { CloseBtn } from "../closeBtn";

export function ModalAlertaBoleto(props: ModalConfig) {
    const { open, handleClose } = props;
    return (
        <ModalBottom open={open} handleClose={handleClose}>
            <AlertaBoleto />
            <CloseBtn />
        </ModalBottom>
    );
}
