import { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { HeaderPassos } from "../HeaderPassos";

import { useTranslation } from "../../../contexts/Localization";
import { usePassos } from "../../../Hook/UsePassos";
import { UseModal } from "../../../Hook/Usemodal";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";

import { Modal } from "./modal";
import { Change } from "./change";
import ModalBottom from "../../ModalBottom";
import { Ativar } from "./ativar";
import { Validadar } from "../Validar";
import { Container } from "./styles";
import { useStateUser } from "../../../Hook/user/UseStateUser";
import { useUser } from "../../../Hook/user/UseUser";

export const arrPassos = [
    {
        passo: 1,
        active: true,
        completed: true,
    },
    {
        passo: 2,
        active: false,
        completed: false,
        goBack: true,
    },
    // {
    //     passo: 3,
    //     active: false,
    //     completed: false,

    // },
];

export const arrPassosOptions = [
    {
        passo: 1,
        active: true,
        completed: true,
    },
    {
        passo: 2,
        active: false,
        completed: false,
    },
    {
        passo: 3,
        active: false,
        completed: false,
    },
    {
        passo: 4,
        active: false,
        completed: false,
        goBack: true,
    },
];

export function AtivarCartaoAvulso() {
    const { t } = useTranslation();
    const { setPassos, passos, dados } = usePassos();
    const { setState, state } = UseModal();
    const { ativar, cartao } = UseCartoes();
    const { forceActivateAccountLogin } = useStateUser();
    const { closeAllModais } = UseModal();
    const { getUser } = useUser();

    const history = useHistory<{ id: number }>();
    const id = history.location?.state?.id;
    const hasMobile = history?.location?.state?.id === 0;
    const width = window.screen.width;
    const [toggle, setToggle] = useState<boolean>(false);

    useEffect(() => {
        if (cartao?.tipo === "cartao_virtual" && state.ativarCartaoAvulso) {
            setPassos(arrPassos);
        } else if (
            state?.ativarCartaoAvulso &&
            (cartao?.tipo === "cartao_fisico" ||
                cartao?.tipo === "cartao_avulso")
        ) {
            setToggle(true);
            setPassos(arrPassos);
        }
    }, [setPassos, state, cartao?.tipo]);

    useEffect(() => {
        if (state?.ativarCartaoAvulsoSubHeader) {
            setToggle(false);
            setPassos(arrPassos);
        }
    }, [cartao?.tipo, state?.ativarCartaoAvulsoSubHeader, setPassos]);

    useEffect(() => {
        if (id === 0 || cartao?.tipo === "cartao_virtual") {
            setToggle(false);
            setPassos(arrPassos);
            return;
        }

        if (id) {
            setToggle(true);
            setPassos(arrPassos);
            return;
        }
    }, [id, setPassos, cartao?.tipo]);

    async function handleClick() {
        try {
            const response = await ativar({ ...dados });

            if (response.status === 200) {
                closeAllModais();
                setPassos([]);
                await getUser();
                if (hasMobile) {
                    history.goBack();
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Container>
            <HeaderPassos
                title={t("Ativar Cartão")}
                handleClose={() => {
                    if (forceActivateAccountLogin) {
                        return;
                    }

                    if (width <= 480) {
                        history.goBack();
                    } else {
                        setState({
                            ...state,
                            ativarCartaoAvulso: false,
                            ativarCartaoAvulsoSubHeader: false,
                        });
                        setPassos([]);
                    }
                }}
            />

            <div className="container-tabs">
                <div className={`tab ${passos[0]?.active ? "active" : ""}`}>
                    {toggle && passos[0]?.active ? (
                        <Change setToggle={setToggle} />
                    ) : null}
                    {!toggle && passos[0]?.active ? <Ativar /> : null}
                </div>
                <div className={`tab ${passos[1]?.active ? "active" : ""}`}>
                    {passos[1]?.active ? (
                            <Validadar handleClick={handleClick} />
                    ) : null}
                </div>
            </div>

            <ModalBottom
                open={state?.cvv}
                handleClose={() => {
                    setState({ ...state, cvv: false });
                    setPassos([]);
                }}
            >
                <Modal />
            </ModalBottom>
        </Container>
    );
}
