import styled from "styled-components";

export const Container = styled.div`
    width: 90%;
    margin: 0 auto;
    padding-top:4px !important;
    p {
        margin-bottom: 16px;
        margin-top: 44px;
        
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        color: ${(props) => props.theme.colors.primary};
    }

    form{
        input{
            height: 40px;
            padding-left:43px;

            font-family: Oxanium;
            font-size:14px;
            letter-spacing:0;
            text-transform:none;
        }

        .container-input.chave-cartao:before{
            left: 12px;
        }

        .container-input.olho button{
            right: -4px;
        }

        .mt-auto{
            button{
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 40px;
                margin-top:30px;

                font-family: Oxanium;
                letter-spacing:0;
                text-transform:none;
            }
        }
    }
`;