import styled from "styled-components";

export const CloseButton = styled.button`
    position: absolute;
    width:44px;
    height:44px;
    right: 13px;
    top:10px;
    z-index:999; 
    opacity: 0.6;

    &:hover {
        opacity: 1;
    }

    &:disabled,
    &[disabled]{
        opacity: 0.6;
    }

    transition: all 0.3s ease-in-out;
`;
