import { AxiosError } from "axios";
import { memo, useCallback, useEffect, useState } from "react";
import { api } from ".";
import { Modais, ModaisKeys, UseModal } from "../Hook/Usemodal";
import ModalError from "../components/ModalError";
import {
    calcularTempoDecorridoEmSegundos,
    getLocalStore,
    getToken,
    msg422,
} from "../util/helper";
import { notRefreshToken } from "./config";
import { ExpiresIn } from "../util/ExpiresIn";
const baseUrl = process.env.REACT_APP_BASE_HTTP;
const baseUrlSistema = process.env.REACT_APP_BASE_HTTP_SISTEMA;

interface DefaultErroMessage {
    message: string;
    success: boolean;
}

type keyOf<T> = keyof T;
let controllerHasRefreshToken = 0;

function Interceptor() {
    const { setState, state, handleOpen } = UseModal();
    const hasSistema = window.location.href.indexOf("sistema") > 0;
    const typeToken = hasSistema ? "admin" : "user";
    const urlApi = hasSistema ? baseUrlSistema : baseUrl;
    const [redirect,setRedirect] = useState("")

    const handleRedirect = (url:string) => {
        window.location.href = url
    }

    const handleClose =  () => {
            setState({
                ...state,
                errorGeral: {
                    active: false,
                    message: "",
                    title: "",
                },
            });
        }

    const refeshToken = useCallback(async () => {
        try {
            const response = await api.post<any>("usuario/refresh");
            const newToken = response.data.access_token;
            const localStore = JSON.parse(localStorage.getItem("user") || "{}");

            localStorage.setItem(
                "user",
                JSON.stringify({
                    ...localStore,
                    token_user: !hasSistema ? newToken : "",
                    token_adm: hasSistema ? newToken : "",
                    time: new Date(),
                })
            );

            return newToken;
        } catch (error) {
            return error;
        }
    }, [hasSistema]);

    const openModal = useCallback(
        (title: string, message: string) => {
            const keys: ModaisKeys[] = Object.keys(state) as keyOf<Modais>[];
            let newState: Modais = {} as Modais;

            keys.forEach((item) => {
                if (item !== "errorGeral" && item !== "successGeral") {
                    newState[item] = false;
                }
            });

            setState({
                ...newState,
                successGeral: { ...state?.successGeral },
                errorGeral: {
                    active: true,
                    message,
                    title,
                },
            });
        },
        [setState, state]
    );

    const errorHandler = useCallback(
        async (error: AxiosError<DefaultErroMessage>) => {
            const { response } = error;

            const url = response?.config.url;
            const exceptionLogin = ["login", "register"];
            // const exceptionPath = ["conta/pay"];

            // if (exceptionPath.includes(url ? url : "")) {
            //     return Promise.reject(error);
            // }

            if (url?.includes("login")) return Promise.reject(error);

            if (url?.includes("conta/details") && response?.status !== 401) {
                setState({
                    ...state,
                    boleto: false,
                    boletoErroMsg: response?.data.message,
                });

                return Promise.reject(error);
            }

            if (url === "register" && response?.status === 500) {
                handleOpen("acessoBloqueado", {
                    message: response?.data?.message,
                });
                return Promise.reject(error);
            }

            if (exceptionLogin.includes(url ? url : "")) {
                let msg = "Confira se os dados foram digitados corretamente e tente novamente.";

                if (url === "register" && response?.status === 422 && (response?.data as any)?.phone_number[0]) {
                    msg = (response?.data as any)?.phone_number[0]
                }
                    
                if ((response?.data as any)?.status === 0) {
                    msg =
                        "Usuário bloqueado por segurança. Entre em contato através do Fale Conosco.";
                } else {
                    const count = (response?.data as any)?.attempts;
                    if (count >= 3)
                        msg =
                            "Usuário bloqueado por segurança. Entre em contato através do Fale Conosco.";
                }

                openModal("Acesso Não Autorizado", msg);
                return Promise.reject(error);
            }

            switch (response?.status) {
                case 401:

                    const expiresIn = new ExpiresIn() 

                    if(expiresIn.checkTooLongTime()){
                        window.location.href = "/login"
                    }else{
                        openModal("Sua Sessão Expirou", "Para continuar, faça<br />o login novamente.");
                        setRedirect("/login")
                    }
                    localStorage.clear();
                    break;
                case 404:
                    const msg404 = response?.data?.message
                        ? response.data.message
                        : "Confira se os dados foram digitados corretamente e tente novamente.";
                    openModal("Usuário Não Encontrado", msg404);
                    break;

                case 403:
                    openModal(
                        "Acesso Não Autorizado",
                        "Usuário bloqueado por segurança. Entre em contato através do Fale Conosco."
                    );
                    break;

                case 422:

                    let title422 = url?.includes("check-campanha") ? "Não foi Possível Validar" : "Algo deu Errado";
                    let message422 = response?.data ? msg422(response.data) : "Dados Inválidos";

                    if (url?.includes("consult-documents")) {
                        title422 = "Acesso Não Autorizado";
                        message422 = "Este usuário já existe";
                    }

                    openModal(title422, message422);
                    break;
                case 500:
                    let title500 = "Erro";
                    if(url?.includes("conta/pay")) title500 = "Algo deu Errado"
                    if(url?.includes("resgate")) title500 = "Ops..."  
                    const msg500 = response?.data?.message
                        ? response.data.message
                        : "Erro Interno do Servidor";
                    openModal(title500, msg500);
                    break;

                default:
                    break;
            }

            return Promise.reject({ ...error });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [openModal]
    );

    const successHandler = useCallback(
        async (response) => {
            const currentUrl = response?.request?.responseURL || "";
            const token = getToken(typeToken);
            if (currentUrl) {
                const hasLogin = currentUrl.replace(urlApi, "");
                if (!notRefreshToken.includes(hasLogin) && token) {
                    const storage = getLocalStore();
                    const pastTime = calcularTempoDecorridoEmSegundos(
                        new Date(storage.time)
                    );

                    if (pastTime >= 30 && controllerHasRefreshToken === 1) {
                        setTimeout(() => {
                            controllerHasRefreshToken = 0;
                        }, 20000);
                        return Promise.resolve(response);
                    }

                    if (pastTime >= 30 && controllerHasRefreshToken === 0) {
                        controllerHasRefreshToken = 1;
                        refeshToken();
                        return Promise.resolve(response);
                    }
                }
            }
            return Promise.resolve(response);
        },
        [refeshToken, typeToken, urlApi]
    );

    useEffect(() => {
        api.interceptors.response.use(
            (response) => successHandler(response),
            (error: AxiosError<DefaultErroMessage>) => errorHandler(error)
        );
    }, [successHandler, errorHandler]);

    return (
        <>
            <ModalError
                open={state?.errorGeral?.active}
                handleClose= {redirect ? () => handleRedirect(redirect) : handleClose}
            />
        </>
    );
}

export default memo(Interceptor);
