import {CloseIcon} from "../../../assets/img/close-icon";
import { CloseButton } from "./styles";
import { UseModal } from "../../../Hook/Usemodal";

type CloseBtnProps ={
    disabled?: boolean;
    color?: string;
    handleClose?: () => void;
}

export function CloseBtn({disabled=false, color="#000",  handleClose}: CloseBtnProps){ 
    const {  closeAllModais} = UseModal();

    function handleClick(){

        if( handleClose ){
            handleClose();
        }else{
            closeAllModais();
        }
    }
    
    return (
        <CloseButton disabled={disabled} onClick={handleClick}>
            <CloseIcon color={color} />
        </CloseButton>
    );
}
