import { useHistory } from "react-router-dom";
import { ComprovanteDePagamento } from "../../components/ComprovanteDePagamento";
import { CloseBtn } from "../../components/Modais/closeBtn";
import { ComprovanteProvider } from "../../Hook/user/useComprovante";
import {Container } from "./styles";

export function PageComprovantes() {
    const history = useHistory();

    function handleClick(){
        history.goBack();
    }

    return (
        <ComprovanteProvider>
            <Container onClick={handleClick}>
                <CloseBtn color={"#57B847"}/>   
            </Container>
            <ComprovanteDePagamento />
        </ComprovanteProvider>
    );
}
