import { ModalConfig } from "../config";
import ModalBottom from "../../ModalBottom";
import { ComprovanteDePagamento } from "../../ComprovanteDePagamento";
import { ComprovanteProvider } from "../../../Hook/user/useComprovante";
import { CloseBtn } from "../closeBtn";

export function ModalComprovanteDePagamento(props: ModalConfig) {
    const { open, handleClose } = props;

    return (
        <ModalBottom open={open} handleClose={handleClose}>
            <ComprovanteProvider>
                <ComprovanteDePagamento handleClose={handleClose}/>
            </ComprovanteProvider>
            <CloseBtn />
        </ModalBottom>
    );
}
