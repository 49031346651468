import { ModalConfig } from "../config";

import { UseModal } from "../../../Hook/Usemodal";
import { FirstStrikeModal } from "../../AvisoSenhaInvalida/FirstStrike";
import { SecondStrikeModal } from "../../AvisoSenhaInvalida/SecondStrike";
import { ThirdStrikeModal } from "../../AvisoSenhaInvalida/ThirdStrike";
import Modal from "../../NovoModal";
import { CloseBtn } from "../closeBtn";

export function ModalAvisoSenhaInvalida(props: ModalConfig) {
    const { open, handleClose } = props;
    const { state } = UseModal();

    const firstStrike = state.dados?.attempts === 1;
    const secondStrike = state.dados?.attempts === 2;

    return (
        <Modal open={open} handleClose={handleClose}>
            {open ? (
                firstStrike ? (
                    <FirstStrikeModal />
                ) : secondStrike ? (
                    <SecondStrikeModal />
                ) : (
                    <ThirdStrikeModal />
                )
            ) : (
                <></>
            )}
            <CloseBtn/>
        </Modal>
    );
}
