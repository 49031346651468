import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import imgWhats from "../../../assets/img/whats.svg";
import imgSms from "../../../assets/img/sms.svg";

export const ModalStyles = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
        width: "90%",
        minWidth: "90%",
        height: "auto",
        padding: "30px",
        paddingTop: "34px",
    },
    ".container": {
        width: "100%",
    },

    "@media (min-width: 768px)": {
        "& .MuiPaper-root": {
            width: "375px",
            minWidth: "auto",
        },
    },
}));

export const ValidadarStyles = styled.div`
    .container-logo-validar {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #e1e2e3;
        img {
            margin-bottom: 25px;
        }
    }

    .enviar-sms {
        margin-top: 65px;
        height: 40px;
        font-family: "Oxanium";
        img {
            margin-right: 11px;
        }
    }

    .container-reenviar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        button {
            &.mais-opcao {
                font-family: "Oxanium";
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
                text-align: center;
                letter-spacing: 0.08em;
                text-transform: uppercase;
                color: ${({ theme }) => theme.colors.secondary};
                width: 100%;
                outline: none;
                margin: 0 auto;
                margin-top: 20px;
                margin-bottom: 29px;
                &.active {
                    img {
                        transform: rotateX(0deg);
                        transition: all 0.5s ease;
                    }
                }
                img {
                    transform: rotateX(180deg);
                    margin-right: 11px;
                }
            }
        }
    }

    .container-btns {
        height: 0px;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
        &.active {
            height: 60px;
            transition: all 0.3s ease-in-out;
        }
        button {
            font-family: "Oxanium";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            text-align: center;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: ${({ theme }) => theme.colors.secondary};
            width: 100%;
            border: 2px solid ${({ theme }) => theme.colors.secondary};
            border-radius: 50%;
            border-radius: 32px;
            margin: 0px;
            margin-bottom: 8px;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                margin-right: 8px;
            }
        }
    }

    button {
        &.re-eneviar-sms {
            font-family: "Oxanium";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            text-align: center;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: ${({ theme }) => theme.colors.secondary};
            display: flex;
            align-items: center;
            margin: 0 auto;
            margin-top: 20px;
            margin-bottom: 29px;
            img {
                margin-right: 11px;
                path {
                    fill: ${({ theme }) => theme.colors.secondary};
                }
            }
        }
    }

    .container-timer {
        padding-top: 30px;
        margin-bottom: 23px;
        &.active {
        }
    }

    .container-submit {
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 29px;
        button {
            height: 40px;
            font-family: "Oxanium";
        }
        &.disabled {
            margin-top: 0px;
            .container-tipo {
                .card {
                    cursor: not-allowed;
                    opacity: 0.3;
                }
            }
        }
        p {
            padding-top: 30px;
            padding-bottom: 19px;
        }
    }

    .container-input {
        margin-bottom: 32px;
        input {
            font-family: "Oxanium";
            font-weight: bold;
            line-height: 100%;
            text-align: center;
            font-size: 14px !important;
            letter-spacing: 0px;
            color: ${({ theme }) => theme.colors.primary};
            font-size: ${({ theme }) => theme.fonts.md};
            padding: 0;
            height: 40px;
            background-color: #e3e3e3;
            border: none !important;
        }
    }

    .container-tipo {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;
        .card {
            position: relative;
            background: ${({ theme }) => theme.colors.white};
            border: 1px solid ${({ theme }) => theme.colors.secondarydark};
            height: 93px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: end;
            padding-bottom: 21px;
            border-radius: 16px;
            cursor: pointer;
            transition: all 0.5s;
            small {
                color: ${({ theme }) => theme.colors.secondarydark};
                transition: all 0.5s;
            }

            &.active {
                background: ${({ theme }) => theme.colors.secondarydark};
                transition: all 0.5s;
                &:before {
                    background-color: ${(props) =>
                        props.theme.colors.white} !important;
                    transition: all 0.5s;
                }
                small {
                    color: ${({ theme }) => theme.colors.white};
                    transition: all 0.5s;
                }
            }
            &:hover {
                background: ${({ theme }) => theme.colors.secondarydark};
                transition: all 0.5s;
                &:before {
                    background-color: ${(props) =>
                        props.theme.colors.white} !important;
                    transition: all 0.5s;
                }
                small {
                    color: ${({ theme }) => theme.colors.white};
                    transition: all 0.5s;
                }
            }

            &.whats {
                &:before {
                    -webkit-mask: url(${imgWhats}) no-repeat;
                    position: absolute;
                    top: 35%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    transition: all 0.5s;
                }
            }
            &.sms {
                &:before {
                    -webkit-mask: url(${imgSms}) no-repeat;
                    position: absolute;
                    top: 35%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    transition: all 0.5s;
                }
            }

            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: 27px;
                transform: translateY(-50%);
                background-color: ${(props) => props.theme.colors.secondary};

                width: 24px;
                height: 24px;
                z-index: 20;
                transition: all 0.5s;
            }
        }
    }
`;

export const LoaderContainer = styled.div`
    width: 100%;
    height: 107px;
    padding-top: 17px;
`;
