import styled from "styled-components";

export const Container = styled.div`
    background-color: ${(props) => props.theme.colors.secondary};
    min-height: 100vh;
    width: 100%;
    padding-top: 115px;
    align-items: center;
    .container-img {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 18px;
    }
    .container-qrcode {
        width: 250px;
        height: 250px;
        margin: 0 auto;
        margin-bottom: 16px;
        img {
            width: 100%;
            height: 100%;
        }
    }

    h3 {
        color: ${(props) => props.theme.colors.white};
        text-transform: uppercase;
        font-size: 20px;
        text-align: center;
        margin-bottom: 32px;
    }

    h4 {
        color: ${(props) => props.theme.colors.white};
        text-transform: uppercase;
        font-size: 20px;
        text-align: center;
        margin-bottom: 32px;
    }

    p {
        color: ${(props) => props.theme.colors.white};
        font-size: 14px;
        text-align: center;
        margin-bottom: 32px;
    }

    button {
        background: #f17e21;
        border-radius: 44px;
        font-style: normal;
        font-weight: 900;
        font-size: 16px;
        line-height: 41px;
        color: #ffffff;
        width: 249px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        height: 46px;
        margin-top: 41px;
        position: relative;
        &:before {
            border-radius: 600px;
        }
        img {
            margin-right: 9px;
        }
    }
`;

export const LoaderContainer = styled.div`
    width: 100%;
    height: 129px;
    padding-top: 17px;
`;