/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from "react";

import { UseLogin } from "../../../Hook/user/UseLogin";
import { useFormLogin } from "../../../Hook/user/useFormLogin";
import { UseModal } from "../../../Hook/Usemodal";
import { FooterLogin } from "./footer";
import { ContentLogin } from "./contentLogin";
import { Manutencao } from "./manutencao";
import ModalRegister from "../ModalRegisterLogin";
import Modal from "../../NovoModal";
import ModalBottom from "../../ModalBottom";

import {  Container, ReenviarContainer} from "./styles";

import { VincularUsuario } from "../VincularUsuario";
import { ModalCheckUser } from "./modalCheckUser";
import { CadastroEfetuado } from "../../CadastroEfetuado";
import { api_without_interceptors } from "../../../services";
import { Reenviar } from "../ValidarDeslogado/reenviar";
import { CloseBtn } from "../../Modais/closeBtn";

export function Logindesktop() {
    const width = window.screen.width;
    const { modal, setModal, user } = UseLogin();
    const { state, handleClose } = UseModal();
    const { resetForm } = useFormLogin();
    const [register, setRegister] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const hasRegister = ref.current && register;

    useEffect(() => {
        if (hasRegister) {
            window.scrollTo({
                top: ref.current.offsetTop,
                behavior: "smooth",
            });
        }
    }, [register, hasRegister]);

    useEffect(() => {
        const storagedUser = localStorage.getItem("user");

        if (storagedUser) {
            api_without_interceptors.post("logout");
        }

        localStorage.clear()
    }, [])

    return (
        <Container className={`${register ? "active" : ""}`}>
            <div className="content-img-text">
               <ContentLogin
                    ref={ref}
                    hasRegister={hasRegister}
                    setRegister={setRegister}
                />

                {/* <Manutencao />  */}
            </div>

            {width <= 768 && !hasRegister && <FooterLogin />}

            {modal ? (
                <ModalRegister
                    open={modal}
                    handleClose={() => setModal(false)}
                />
            ) : (
                <></>
            )}

            {state.cadastroEfetuado ? (
                <Modal open={state.cadastroEfetuado} handleClose={() => { }}>
                    <CadastroEfetuado />
                </Modal>
            ) : (
                <></>
            )}

            <Modal
                open={state.vincularUsuarioCampanha}
                handleClose={() => {
                    handleClose("vincularUsuarioCampanha");
                    resetForm();
                }}
            >
                <VincularUsuario />
            </Modal>
            <ModalBottom
                open={state.loginCheck}
                handleClose={() => {
                    handleClose("loginCheck");
                    resetForm();
                }}
            >
                {state.loginCheck ? (
                    <>
                        <ModalCheckUser phone={user?.phone_number || ""} />
                        <ReenviarContainer> 
                            <Reenviar />
                        </ReenviarContainer>
                        <CloseBtn />
                    </>
                ) : (
                    <></>
                )}
            </ModalBottom>
        </Container>
    );
}
