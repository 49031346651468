import exclamation from "../../../assets/img/exclamation.svg"
import { UseModal } from "../../../Hook/Usemodal";
import { Button } from "../../Mobile/ButtonSlim";
import { Container } from "../../Modais/ModalContainer/styles"

export const FirstStrikeModal = () => {
    const { handleClose } = UseModal();

    function handleCloseModal() {
        handleClose("avisoSenhaInvalida");
    }

    return (
        <Container>
            <img className="img-close" src={exclamation} alt="" />

            <h2>Algo deu Errado</h2>

            <p>
                Confira se os dados foram digitados corretamente e tente
                novamente.
            </p>
            <div className="buttonContainer">
                <Button  onClick={handleCloseModal}>Ok</Button>
            </div>
        </Container>
    );
};
