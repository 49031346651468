import { Box } from "alisson-application";
import { useEffect, useState, memo } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "../../../contexts/Localization";
import { useTheme } from "../../../contexts/ThemeContext";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { usePassos } from "../../../Hook/UsePassos";
import { UseModal } from "../../../Hook/Usemodal";
import { HeaderPassos } from "../HeaderPassos";
import { objCartaoes } from "../../../util/helper";
import { Card, CardProps } from "../Card";
import { List, ListDados } from "./list";
import { Container } from "./styles";

const arr = [
    {
        passo: 1,
        active: false,
        completed: false,
    },
];

function DadosCartao() {
    const { t } = useTranslation();
    const { cartao, dadosCartao } = UseCartoes();
    const { currentTheme } = useTheme();
    const { setPassos } = usePassos();
    const { state, handleClose } = UseModal();
    const history = useHistory();
    const [item, setItem] = useState<CardProps>({} as CardProps);
    const [dados, setDados] = useState<ListDados>({} as ListDados);
    const width = window.screen.width;
    const hasShowDadosCartao = width <= 480 || state?.visualizarDados;

    const fake = {
        id: 0,
        price: "0.00",
        number: "**** **** **** ****",
        date: "**/**",
        type:
            cartao?.tipo === "cartao_fisico" || cartao?.tipo === "cartao_avulso"
                ? t("FÍSICO")
                : t("VIRTUAL"),
        status: "ATIVO",
        color:
            cartao?.tipo === "cartao_fisico" || cartao?.tipo === "cartao_avulso"
                ? currentTheme.colors.tertiarybright
                : currentTheme.colors.tertiarydark,
    };

    useEffect(() => {
        if (hasShowDadosCartao) {
            setPassos(arr);
        }
    }, [setPassos, hasShowDadosCartao]);

    useEffect(() => {
        if (cartao?.id) {
            const item = objCartaoes([{ ...cartao }], false);
            setItem(item[0]);
            (async () => {
                const response = await dadosCartao(cartao.id);
                const number = response.acg_account_card_number.replace(
                    /^([0-9]{4})([0-9]{4})([0-9]{4})([0-9]{4})/g,
                    "$1 $2 $3 $4"
                );
                const date = response.acg_account_card_expiration.replace(
                    /^([0-9]{2})([0-9]{2})/g,
                    "$2/$1"
                );

                const dados = {
                    id: response.id,
                    number: number,
                    date: date,
                    cvv: response.acg_account_card_cvv,
                    conta: response.acg_account_id,
                };
                setDados(dados);
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <HeaderPassos
                title={t("Dados do Seu Cartão")}
                handleClose={() => {
                    if (width <= 480) {
                        history.push("/detalhe-movimentacao");
                    } else {
                        handleClose("visualizarDados");
                        setPassos([]);
                    }
                }}
            />
            <Container>
                <Box m="22px auto 28px" width="190px">
                    {hasShowDadosCartao ? (
                        <Card
                            {...item}
                            showPrice={true}
                            visualizarDados={false}
                        />
                    ) : (
                        <Card
                            {...fake}
                            showPrice={false}
                            visualizarDados={false}
                        />
                    )}
                </Box>

                <List {...dados} />
            </Container>
        </>
    );
}

export const MemorizedDadosCartao = memo(DadosCartao);
