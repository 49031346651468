import { Perfil } from "../../components/Perfil";
import { UseStateProvider } from "../../Hook/user/usePerfil";
import {Container } from "./styles";


export function PagePerfil() {
    return (
        <UseStateProvider>
            <Container>
                <Perfil />
            </Container>
        </UseStateProvider>
    );
}
 