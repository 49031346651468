import { useEffect } from "react";

import { useHistory } from "react-router-dom";
import { HeaderPassos } from "../HeaderPassos";

import { useTranslation } from "../../../contexts/Localization";
import { usePassos } from "../../../Hook/UsePassos";
import { UseModal } from "../../../Hook/Usemodal";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";

import { Modal } from "./modal";
import ModalBottom from "../../ModalBottom";
import { Ativar } from "./ativar";
import { Validadar } from "../Validar";
import { Finalizar } from "./finalizar";
import { Container } from "./styles";

export const arrPassos = [
    {
        passo: 1,
        active: true,
        completed: true,
    },
    {
        passo: 2,
        active: false,
        completed: false,
    },
    {
        passo: 3,
        active: false,
        completed: false,
        goBack: true,
    },
];

export function AtivarCartao() {
    const { t } = useTranslation();
    const { setPassos, passos, dados, setDados, nextPasso } = usePassos();
    const { setState, state } = UseModal();
    const { ativar } = UseCartoes();

    const history = useHistory();
    const hasPath = history.location.pathname.includes("/ativar-cartao");
    const width = window.screen.width;

    useEffect(() => {
        if (state?.ativarCartao || hasPath) {
            setPassos(arrPassos);
        }
    }, [setPassos, hasPath, state]);

    async function handleClick() {
        const responseStore = await ativar(dados);

        if (responseStore?.status === 200) {
            setDados({
                ...dados,
                ...responseStore.data,
            });
            nextPasso(3);
        }
    }

    return (
        <Container>
            <HeaderPassos
                title={t("Ativar Cartão")}
                handleClose={() => {
                    if (width <= 480) {
                        history.goBack();
                    } else {
                        setState({ ...state, ativarCartao: false });
                        setPassos([]);
                    }
                }}
            />

            <div className="container-tabs">
                <div className={`tab ${passos[0]?.active ? "active" : ""}`}>
                    {passos[0]?.active ? <Ativar /> : null}
                </div>
                <div className={`tab ${passos[1]?.active ? "active" : ""}`}>
                    {passos[1]?.active ? (
                            <Validadar handleClick={handleClick} />
                        
                    ) : null}
                </div>
                <div className={`tab ${passos[2]?.active ? "active" : ""}`}>
                    {passos[2]?.active ? <Finalizar /> : null}
                </div>
            </div>

            <ModalBottom
                open={state?.cvv}
                handleClose={() => {
                    setState({ ...state, cvv: false });
                    setPassos([]);
                }}
            >
                <Modal />
            </ModalBottom>
        </Container>
    );
}
