import * as React from "react";

import { UseModal, Modais } from "../../Hook/Usemodal";
import { Button } from "../Mobile/ButtonSlim";

import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

import { ModalStyles} from "./styles";
import { Container } from "../../components/Modais/ModalContainer/styles";
import imgSuccess from "../../assets/img/sucesso.svg";
import { CloseBtn } from "../Modais/closeBtn";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});
type keyOf<T> = keyof T;

export function ModalSuccess() {
    const { state, setState } = UseModal();

    function handleClose() {
        const keys = Object.keys(state) as keyOf<Modais>[];
        let newState: Modais = {} as Modais;

        keys.forEach((item) => {
            if (item !== "errorGeral" && item !== "successGeral") {
                newState[item] = false;
            }
        });

        setState({
            ...newState,
            errorGeral: { ...state?.errorGeral },
            successGeral: {
                active: false,
                message: "",
                title: "",
            },
        });
    }

    return (
        <ModalStyles
            open={state?.successGeral?.active}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            className="modal-success"
        >
            <Container>
                <img src={imgSuccess} alt="" />
                <h2 className="success" dangerouslySetInnerHTML={{  __html: state?.successGeral.title, }}></h2>
                <p dangerouslySetInnerHTML={{__html: state?.successGeral.message,}}></p>
                <Button onClick={handleClose}>Ok</Button>
                <CloseBtn/>
            </Container>
        </ModalStyles>
    );
}
