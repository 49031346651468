import { UseModal } from "../../../Hook/Usemodal";
import { UseLogin } from "../../../Hook/user/UseLogin";
import { Validadar } from "../ValidarDeslogado";

interface ModalCheckUserProps {
    phone: string;
}

export function ModalCheckUser(props: ModalCheckUserProps) {
    const { phone } = props;
    const { handleClose, setState, state } = UseModal();
    const { user } = UseLogin();
    return (
        <>
            <Validadar
                pt={true}
                phone_number={phone || ""}
                handleClick={() => {
                    if (user.first_update_password !== 0) {
                        handleClose("loginCheck");
                    } else {
                        setState({
                            ...state,
                            loginCheck: false,
                            resetarSenha: true,
                        });
                    }
                }}
            />
        </>
    );
}
