import { useCallback, useEffect, useState } from "react";
import { Box, Flex } from "alisson-application";
import { GridRenderCellParams, GridColumns } from "@mui/x-data-grid-pro";
import { Button } from "../../Button/status";
import TableUser from "../../Table";
import { TableStyles } from "./styles";
import { Saldo } from "./saldo";
import Select, { options } from "../../NovoSelect";
import imgOlho from "../../../../assets/img/olho.svg";
import imgUpdate from "../../../../assets/img/update.svg";
// import imgPassword from "../../../../assets/img/senha-ativar-cartao.svg";
import imgEntrega from "../../../../assets/img/entrega.svg";
import { Data } from "./config";
import { useUser } from "../../../../Hook/system/useUser";
import { Cartoes } from "../../../../Hook/user/UseCartaoes";

import { ExtratoCartao } from "./extrato";
import { Entrega } from "./entrega";
import Modal from "../../../NovoModal";
import { Loader } from "../../../Loader";
// import { Mudarsenha } from "../Mudarsenha";
import { formatDateIsoSystem } from "../../../../util/helper";

export function Table() {
    const width = 1645 / 10;
    const {
        user,
        getExtrato,
        setCampanhaId,
        campanhaId,
        loading,
        updateCardsAcg,
    } = useUser();
    const [cartoes, setCartoes] = useState<Cartoes[]>([]);
    // const [cartao, setCartao] = useState<Cartoes | null>(null); // cartao selecionado
    const [modal, setModal] = useState(false);
    const [modalEntrega, setModalEntrega] = useState(false);
    // const [changinPassword, setChanginPassword] = useState(false);
    const [id, setId] = useState<null | number>();

    const option: options[] = user?.campanhas?.map((campanha) => {
        return {
            label: campanha.nome,
            value: campanha.id,
        };
    });

    const hasOptions = useCallback(() => {
        if (campanhaId) {
            return option.find((item) => item.value === campanhaId);
        }

        return option?.length ? option[0] : null;
    }, [campanhaId, option]);

    const dados: Data[] = cartoes.map((cartao) => {
        return {
            id: cartao?.id + "",
            requested_at: cartao?.requested_at
                ? formatDateIsoSystem(cartao?.requested_at)
                : "-",
            produto: cartao.produto?.tipo || "",
            tipo: cartao?.tipo,
            nome_cartao: cartao?.nome_cartao,
            sms: cartao?.acg_sms_service ? "SIM" : "NÃO",
            conta: cartao?.acg_account_id,
            numero:
                cartao?.tipo === "cartao_virtual"
                    ? cartao?.acg_account_card_last4digits
                        ? cartao?.acg_account_card_last4digits
                        : "-"
                    : cartao?.acg_account_card_last4digits,
            status: cartao.status,
            acg_tracking_id: cartao?.acg_tracking_id || "-",
            ver_extrato: "",
            entrega: "",
            ver_saldo: "",
            chaning_password: "",
        };
    });

    const columns: GridColumns = [
        {
            field: "id",
            headerName: "id",
            width: 100,
        },

        // {
        //     field: "tipo",
        //     headerName: "TIPO",
        //     width,
        // },
        {
            field: "requested_at",
            headerName: "Solicitado/Adicionado",
            width,
        },
        {
            field: "produto",
            headerName: "PRODUTO",
            width,
        },

        {
            field: "nome_cartao",
            headerName: "NOME DO CARTÃO",
            width,
        },
        {
            field: "sms",
            headerName: "SMS",
            width,
        },
        {
            field: "conta",
            headerName: "CONTA",
            width,
        },
        {
            field: "numero",
            headerName: "Últimos 4 Números",
            width,
        },
        {
            field: "acg_tracking_id",
            headerName: "Tracking_ID",
            width: 300,
        },
        {
            field: "status",
            headerName: "STATUS",
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <Button
                        className={`${
                            row.status === "ATIVO" ? "" : "SUSPENSA"
                        }`}
                    >
                        {row.status}
                    </Button>
                );
            },
            width,
        },
        {
            field: "entrega",
            headerName: "ENTREGA",
            renderCell: ({ row }: GridRenderCellParams) => {
                if (row.tipo === "cartao_fisico") {
                    return (
                        <button
                            onClick={async () => {
                                setModalEntrega(true);
                                setId(row.id);
                            }}
                        >
                            <img src={imgEntrega} alt="" />
                        </button>
                    );
                }

                return <></>;
            },
            width,
        },
        {
            field: "ver_extrato",
            headerName: "VER EXTRATO",
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <button
                        onClick={async () => {
                            setModal(true);
                            setId(row.id);
                            getExtrato(row.id);
                        }}
                    >
                        <img src={imgOlho} alt="" />
                    </button>
                );
            },
            width: 100,
        },
        {
            field: "ver_saldo",
            headerName: "VER SALDO",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Saldo id={row.id} />;
            },
            width: 100,
        },
        // {
        //     field: "chaning_password",
        //     headerName: "ALTERAR SENHA",
        //     renderCell: ({ row }: GridRenderCellParams) => {
        //         function handleClick() {
        //             const id = parseInt(row.id);
        //             const cartao = cartoes.find((item) => item.id === id);
        //             if (cartao) {
        //                 setChanginPassword(true);
        //                 setId(row.id);
        //                 setCartao(cartao);
        //             }
        //         }

        //         return (
        //             <button onClick={handleClick}>
        //                 <img src={imgPassword} alt="" />
        //             </button>
        //         );
        //     },
        //     width: 100,
        // },
    ];

    useEffect(() => {
        setCartoes(user?.cartoes ? user?.cartoes : []);
    }, [user?.cartoes]);

    if (loading) {
        return (
            <div className="container-loading">
                <Loader />
            </div>
        );
    }

    return (
        <TableStyles>
            <Flex>
                <Box width="307px" mr="16px">
                    <Select
                        initalValue={hasOptions()}
                        options={option}
                        label="CAMPANHA"
                        icon={false}
                        onChange={(values) => {
                            setCampanhaId(parseInt(values.value + ""));
                        }}
                    />
                </Box>
                <button onClick={updateCardsAcg}>
                    <img src={imgUpdate} alt="" />
                </button>
            </Flex>

            <TableUser
                rows={dados ? dados : []}
                columns={columns}
                nameCSV="USUÁRIOS"
            />

            {/* <Relatorio /> */}
            <Modal open={modal} handleClose={() => setModal(false)}>
                {modal ? <ExtratoCartao id={id ? id : null} /> : null}
            </Modal>

            <Modal
                open={modalEntrega}
                handleClose={() => setModalEntrega(false)}
            >
                {id ? <Entrega id={id} /> : null}
            </Modal>
            {/* <Modal
                open={changinPassword}
                handleClose={() => setChanginPassword(false)}
            >
                {id ? (
                    <>
                        <Mudarsenha
                            cartao={cartao ? cartao : ({} as Cartoes)}
                            handleSubimit={async (values) => {
                                await alterarSenha(values, id);
                                setChanginPassword(false);
                            }}
                        />
                    </>
                ) : null}
            </Modal> */}
        </TableStyles>
    );
}
