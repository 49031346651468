import * as React from "react";

import { UseModal } from "../../Hook/Usemodal";

import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

import { ModalStyles} from "./styles";
import { Container } from "../Modais/ModalContainer/styles"
import exclamation from "../../assets/img/exclamation.svg"
import crash from "../../assets/img/crashGreen.svg"
import crashRed from "../../assets/img/crash.svg"
import { Button } from "../../components/Mobile/ButtonSlim"
import { CloseBtn } from "../Modais/closeBtn";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

interface ModalProps {
    open: boolean;
    handleClose: () => void;
}

export default function ModalError(props: ModalProps) {
    const { open, handleClose } = props;
    const { state } = UseModal();

    let icon = exclamation;
    if(state?.errorGeral.title === "Ops..." || state?.errorGeral.title === "Sua Sessão Expirou") icon = crash;
    if(state?.errorGeral.title === "Saldo Insuficiente") icon = crashRed;

    let textGreen = ""
    if(state?.errorGeral.title === "Ops..." || state?.errorGeral.title === "Sua Sessão Expirou") textGreen = "success"

    return (
        <ModalStyles
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            className="modal-error"
        >
            <Container>
                <img src={icon} alt="" /> 
                
                <h2 className={textGreen}> 
                    {state?.errorGeral.title}
                </h2>

                {state?.errorGeral.message && (
                    <p
                        dangerouslySetInnerHTML={{
                            __html: state?.errorGeral.message,
                        }}
                    ></p>
                )}
                
                <div className="buttonContainer">
                    <Button onClick={handleClose}>Ok</Button>
                </div>
                <CloseBtn/>
            </Container>
        </ModalStyles> 
    );
}
