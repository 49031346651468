import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { HeaderPassos } from "../HeaderPassos";
import { usePassos } from "../../../Hook/UsePassos";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { UseModal } from "../../../Hook/Usemodal";
import { useTranslation } from "../../../contexts/Localization";
import CardTransferir from "./cardTransferir";
import { ConfirmaDados } from "./confirmaDados";
import { Sucesso } from "./sucesso";
import { Container } from "./styles";
import { Validadar } from "../Validar";
import { FormDigitarSenha } from "../FormDigitarSenha";
import { foraDoHorarioExtendido } from "../../../util/helper";

export const arrPassosTransferir = [
    {
        passo: 1,
        active: true,
        completed: true,
    },
    {
        passo: 2,
        active: false,
        completed: false,
    },
    {
        passo: 3,
        active: false,
        completed: false,
    },
    {
        passo: 4,
        active: false,
        completed: false,
    },
    {
        passo: 5,
        active: false,
        completed: false,
        goBack: true,
    },
];

export function Transferir() {
    const { setState, state, closeAllModais } = UseModal();
    const { setPassos, passos, dados, nextPasso, setDados } = usePassos();
    const { tranferirCartao } = UseCartoes();
    const { t } = useTranslation();
    const history = useHistory();
    const hasPath = history.location.pathname.includes("/transferir");
    const width = window.screen.width;

    async function handleClickTransferir() {
        const values = {
            cartao_from: dados?.transferirCardActive?.id,
            cartao_to: dados?.transferirCurrentCard?.id,
            value: dados?.value,
            password: dados?.password,
        };

        try {
            await tranferirCartao(values);
            nextPasso(5);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (state?.transferir || hasPath) {
            setPassos(arrPassosTransferir);
        }
    }, [setPassos, hasPath, state]);

    if (foraDoHorarioExtendido()) {
        history.push("/dashboard");
        return <></>;
    }

    return (
        <Container>
            <HeaderPassos
                title={t("Transferir Valores")}
                handleClose={() => {
                    if (width <= 480) {
                        history.goBack();
                    } else {
                        setState({ ...state, solicitarResgate: false });
                        setPassos([]);
                        closeAllModais()
                    }
                }}
            />

            <div
                className={`container-tabs ${passos[2]?.active ? "width" : ""}`}
            >
                <div className={`tab ${passos[0]?.active ? "active" : ""}`}>
                    {(passos[0]?.active && state?.transferir) || hasPath ? (
                        <CardTransferir />
                    ) : null}
                </div>

                <div className={`tab ${passos[1]?.active ? "active" : ""}`}>
                    {passos[1]?.active && <ConfirmaDados />}
                </div>
                <div className={`tab ${passos[2]?.active ? "active" : ""}`}>
                    {passos[2]?.active && (
                        <FormDigitarSenha
                            handleSubimit={(values: any) => {
                                setDados({
                                    ...dados,
                                    password: values.password,
                                });
                                nextPasso(4);
                            }}
                        />
                    )}
                </div>
                <div className={`tab ${passos[3]?.active ? "active" : ""}`}>
                    {passos[3]?.active && (
                        <>
                            <Validadar handleClick={handleClickTransferir} />
                        </>
                        
                    )}
                </div>
                <div className={`tab ${passos[4]?.active ? "active" : ""}`}>
                    {passos[4]?.active && <Sucesso />}
                </div>
            </div>
        </Container>
    );
}
