import * as React from "react";

import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Validadar } from "./validar";
import { ModalStyles } from "./styles";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

interface ModalProps {
    open: boolean;
    handleClose: () => void;
}

export default function ModalRegisterLogin(props: ModalProps) {
    const { open, handleClose } = props;

    return (
        <ModalStyles
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <Validadar handleClose={handleClose} />
        </ModalStyles>
    );
}
  