import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { UseModal } from "../../../Hook/Usemodal";
import { usePassos } from "../../../Hook/UsePassos";
import { useUser } from "../../../Hook/user/UseUser";
import { FormCpf } from "./formCpf";
import { UsuarioNaoEncontrado } from "./usuarioNaoEncontrado";
import { ConfirmaDados } from "./confirmaDados";
import { Sucesso } from "./sucesso";
import { FormConfirmaUser } from "./formConfirmaUser";
import { HeaderPassos } from "../HeaderPassos";
import { Validadar } from "../Validar";
import { Container } from "./styles";

export const arrPassos = [
    {
        passo: 1,
        active: true,
        completed: true,
    },
    {
        passo: 2,
        active: false,
        completed: false,
    },
    {
        passo: 3,
        active: false,
        completed: false,
    },
    {
        passo: 4,
        active: false,
        completed: false,
    },
    {
        passo: 5,
        active: false,
        completed: false,
        goBack: true,
    },
];

export function TransferirEntreCateira() {
    const { setState, state, closeAllModais } = UseModal();
    const history = useHistory();
    const { setPassos, passos, dados, nextPasso } = usePassos();
    const { storeTransferBetweenWallets } = useUser();
    const hasPath = history.location.pathname.includes(
        "/transferir-entre-carteiras"
    );
    const width = window.screen.width;

    useEffect(() => {
        if (state?.transferirEntreCarteiras || hasPath) {
            setPassos(arrPassos);
        }
    }, [setPassos, hasPath, state]);

    async function storeTransferenciaEntreCarteiras() {
        const { campanha_from_id, campanha_to_id, user_to_id, valor } = dados;

        try {
            const response = await storeTransferBetweenWallets({
                campanha_from_id,
                campanha_to_id,
                user_to_id,
                valor,
            });

            if (response.status === 200) {
                nextPasso(5);
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Container>
            <HeaderPassos
                title="Transferir Valores"
                handleClose={() => {
                    if (width <= 480) {
                        history.goBack();
                    } else {
                        setState({ ...state, solicitarResgate: false });
                        setPassos([]);
                        closeAllModais()
                    }
                }}
            />

            <div className="container-tabs">
                <div className={`tab ${passos[0]?.active ? "active" : ""}`}>
                    {passos[0]?.active ? <FormCpf /> : null}
                </div>

                <div className={`tab ${passos[1]?.active ? "active" : ""}`}>
                    {passos[1]?.active ? (
                        dados.length ? (
                            <FormConfirmaUser />
                        ) : (
                            <UsuarioNaoEncontrado />
                        )
                    ) : null}
                </div>

                <div className={`tab ${passos[2]?.active ? "active" : ""}`}>
                    {passos[2]?.active ? (
                        <ConfirmaDados handleClick={() => nextPasso(4)} />
                    ) : null}
                </div>

                <div className={`tab ${passos[3]?.active ? "active" : ""}`}>
                    {passos[3]?.active ? (
                        <Validadar
                            handleClick={storeTransferenciaEntreCarteiras}
                        />
                    ) : null}
                </div>
                <div className={`tab ${passos[4]?.active ? "active" : ""}`}>
                    <Sucesso />
                </div>
            </div>
        </Container>
    );
}
