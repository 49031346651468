import { useHistory } from "react-router-dom";
import { usePassos } from "../../../Hook/UsePassos";
import { UseModal } from "../../../Hook/Usemodal";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";

import { Cartao } from "./cartao";
import { CartaoAvulso as TypeCartaoAvulso } from "./config";
import { Validadar } from "../Validar";
import { Container } from "./styles";

export function CartaoAvulso() {
    const { passos, dados } = usePassos();
    const { setState, state } = UseModal();
    const { ativar } = UseCartoes();
    const history = useHistory();
    const width = window.screen.width;

    async function handleClick() {
        const responseStore = await ativar(dados as TypeCartaoAvulso);
        if (responseStore?.status === 200) {
            if (width <= 480) {
                history.goBack();
            } else {
                setState({
                    ...state,
                    cartaoAvulso: false,
                    successGeral: {
                        active: true,
                        message: "Cartão adicionado com sucesso!",
                        title: "Sucesso",
                    },
                });
            }
        }
    }

    return (
        <Container>
            <div className={`tab ${passos[1]?.active ? "active" : ""}`}>
                {passos[1]?.active ? <Cartao /> : null}
            </div>
            <div className={`tab ${passos[2]?.active ? "active" : ""}`}>
                {passos[2]?.active ? (
                    <>
                        <Validadar handleClick={handleClick} />
                    </>
                ) : null}
            </div>
        </Container>
    );
}
