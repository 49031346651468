import styled from "styled-components";

export const Container = styled.div`
    height: 59px;
    border-bottom: 1px solid #e1e2e3;
    button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        width: 100%;

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        /* identical to box height, or 14px */

        text-transform: none;

        /* Gray/01 */

        font-family: "Oxanium";
        color: ${(props) => props.theme.colors.black};

        img {
            margin-right:10px;
        }
    }
`;
