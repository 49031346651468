import { ModalConfig } from "../config";

import { Validadar } from "../../Mobile/Validar";
import { useUser } from "../../../Hook/user/UseUser";
import { UseModal } from "../../../Hook/Usemodal";
import Modal from "../../NovoModal";
import { CloseBtn } from "../closeBtn";

export function ModalUpdateEndereco(props: ModalConfig) {
    const { open, handleClose } = props;
    const { updateEndereco, updateEnderecoUser } = useUser();
    const { setState, state } = UseModal();

    async function update() {
        const response = await updateEndereco(updateEnderecoUser);

        if (response.status === 200) {
            setState({
                ...state,
                alterarEndereco: false,
                updateEndereco: false,
                perfil: false,
                successGeral: {
                    active: true,
                    message: "",
                    title: "Dado Atualizado<br/> com Sucesso!",
                },
            });
        }else{
            setState({
                ...state,
                alterarEndereco: false,
                updateEndereco: false,
                perfil: false,
                errorGeral: {
                    active: true,
                    message: "",
                    title: "Erro ao Atualizar Dado",
                },
            });
        }
    }

    return (
        <Modal open={open} handleClose={handleClose}>
            {open ? <Validadar handleClick={update} pt={true} /> : <></>}
            <CloseBtn />
        </Modal>
    );
}
