import { useHistory } from "react-router-dom";
import { Container } from "./styles";
import imgSetaEsq from "../../../assets/img/seta-esq-verde.svg";
import { CloseIcon } from "../../../assets/img/close-icon";

interface GobackProps {
    title: string;
    onClick?: () => void;
}
export function GoBack(props: GobackProps) {
    const history = useHistory();
    const { title, onClick } = props;

    return (
        <Container className="container">
            <button
                onClick={() => {
                    if (onClick) {
                        onClick();
                    } else {
                        history.push("/dashboard");
                    }
                }}
            >
                <img src={imgSetaEsq} alt="Voltar" />
                {title}
                <CloseIcon color={"#57B847"}/>
            </button>
        </Container>
    );
}
