import { Flex } from "alisson-application";
import { memo } from "react";
import { OverView } from "../OverView";

import { Cartoes } from "./cartoes";
import { Container } from "./styles";
import Modal from "../../ModalBottom";
import { UseModal } from "../../../Hook/Usemodal";
import { ConfirmaToggleSms } from "../../Mobile/DetalheMovimentacao/confirmaToggleSms";
import { CloseBtn } from "../../Modais/closeBtn";

function HomeDesktop() {
    const { handleClose, state } = UseModal();

    return (
        <Container>
            <div className="loader"></div>

            <Flex className="container overview">
                <Cartoes />
                <OverView />
            </Flex>

            <Modal
                open={state?.confirmaToggleSms}
                handleClose={() => handleClose("acaoBloquearCartao")}
            >
                <ConfirmaToggleSms />
                <CloseBtn />
            </Modal>
        </Container>
    );
}

export const HomeDesktopMemo = memo(HomeDesktop);
