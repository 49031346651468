import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 59px;
    border-bottom: 1px solid #e1e2e3;
    position: relative;
    cursor: pointer;

    img {
        margin-right:10px;
    }
    button {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        text-transform: none;
        color: ${({ theme }) => theme.colors.black};
    }

    @media (max-width: 768px) {
        margin: 0 auto;
    }
`;
