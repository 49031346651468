import { ModalConfig } from "../config";
import ModalBottom from "../../ModalBottom";
import { LimitesTarifas } from "../../LimitesTarifas";
import { CloseBtn } from "../closeBtn";

export function ModalLimiteTarifas(props: ModalConfig) {
    const { open, handleClose } = props;
    return (
        <ModalBottom open={open} handleClose={handleClose}>
            <LimitesTarifas handleClose={handleClose}/>
            <CloseBtn />
        </ModalBottom>
    );
}
